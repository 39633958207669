import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import useBoardDetails from '@aurora/shared-client/components/community/Board/useBoardDetails';
import BasePage from '../../../components/page/BasePage/BasePage';
import useNodePageSeoProps from '../../../components/useNodePageSeoProps';

const BoardActionMenu = dynamic(
  () => import('../../../components/community/Board/BoardActionMenu/BoardActionMenu')
);
const NodeSubscriptionAction = dynamic(
  () => import('../../../components/nodes/NodeSubscriptionAction/NodeSubscriptionAction')
);

/**
 * Forum Board Page
 *
 * @author Stephen McLaughry, Adam Ayres, Guido Mininno
 */
const ForumBoardPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { contextNode } = useContext(AppContext);
  const {
    queryResult: { data: boardDetails },
    boardData,
    loading: boardInfoLoading
  } = useBoardDetails();

  const board = boardDetails?.board;
  const nextSeoProps = useNodePageSeoProps(boardData);

  if (boardInfoLoading) {
    return null;
  }

  /**
   * Renders the Board action menu.
   */
  function renderBoardActionMenu(): React.ReactElement {
    return <BoardActionMenu board={board} />;
  }
  /**
   * Renders the node subscription action.
   */
  function renderNodeSubscriptionAction(): React.ReactElement {
    return <NodeSubscriptionAction node={contextNode} />;
  }

  return (
    <BasePage
      pageId={EndUserPages.ForumBoardPage}
      breadcrumbActionComponents={[renderNodeSubscriptionAction, renderBoardActionMenu]}
      nextSeoProps={nextSeoProps}
    />
  );
};

export default ForumBoardPage;
