import type { QueryResult } from '@apollo/client';
import type {
  BoardDetailsQuery,
  BoardDetailsQueryVariables,
  ContextNodeFragment
} from '@aurora/shared-generated/types/graphql-types';
import type { Optional } from '@aurora/shared-types/community';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import boardDetailsQuery from './BoardDetails.query.graphql';

const log = getLog(module);

export interface BoardDetailsQueryData {
  /**
   * Whether the board data is loaded or not
   */
  loading: boolean;
  /**
   * Merged context node and board details data
   */
  boardData: Pick<BoardDetailsQuery, 'board'> & Pick<ContextNodeFragment, 'description'>;
  /**
   * Query result
   */
  queryResult: QueryResult<BoardDetailsQuery, BoardDetailsQueryVariables>;
}

/**
 * This hook is used to fetch details of a board
 * @param skip A boolean to indicate whether the query should be called or not
 * @param boardQueryVariables Optional variables to pass to the query
 * @returns QueryResult of type BoardDetailsQuery
 *
 * @author Guido Mininno
 */
export default function useBoardDetails(
  skip = false,
  boardQueryVariables?: Optional<BoardDetailsQueryVariables, 'id'>
): BoardDetailsQueryData {
  const { contextNode } = useContext(AppContext);
  const variables: BoardDetailsQueryVariables = {
    id: contextNode.id,
    useBoardPolicies: true,
    useCanAdminNode: true,
    useCanViewMessageHistory: true,
    canUpdateSeoFields: true,
    useCanStartOrEditArticleTkb: true,
    useManageArticleTkb: true,
    useCanViewAllDraftsTkb: true,
    useCanStartArticleBlog: true,
    useManageArticleBlog: true,
    useCanViewAllDraftsBlog: true,
    useCanArchiveMessage: true,
    ...boardQueryVariables
  };
  const isBoardContextNode = contextNode.nodeType === NodeType.BOARD;
  const queryResult = useQueryWithTracing<BoardDetailsQuery, BoardDetailsQueryVariables>(
    module,
    boardDetailsQuery,
    { variables, skip: skip || !isBoardContextNode }
  );

  if (queryResult?.loading) {
    return {
      loading: true,
      boardData: null,
      queryResult
    };
  }

  if (queryResult?.error) {
    log.error(queryResult?.error, 'Error getting board details for %O', variables);
  }

  return {
    loading: false,
    boardData: {
      ...queryResult?.data?.board,
      description: contextNode.description
    },
    queryResult
  };
}
